import Vue from 'vue'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import moment from "moment";
import vSelect from 'vue-select';

export default {
    components: {
        vSelect,
    },
    data: function() {
        return {
            base_url: Vue.BASE_URL,
            pageTitle: "Etat financement",
            dataRecuperer: [],
            financementSelection: [],
            crudmodaltitle: "Enregistrer un rembourssement",
            listdata: {
                users: [],
                tresoreries: [],
                libelles: []
            },
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            crudform: {
                id: "",
                date_mvt_formatted: moment().format('DD/MM/YYYY'),
                num_mvt: "",
                type_mvt: "",
                reference_mvt: "",
                libelle_mvt: "",
                description: "",
                montant: "",
                tresoreries: "",
                tresoreries_dest_id: "",
                activites_source_id: "",
                activites_dest_id: "",
                editable: 1
            },
            historiqueDeRemboursement: [],
            totalFinancement: 0,
            totalRemboursement: 0,
            totalReste: 0
        }
    },
    methods: {

        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        getData() {
            var that = this;
            axios.get(that.base_url+"/tresoreriesmouvement/getfinancement").then(response => {
                that.dataRecuperer = response.data;
                var total_financement = 0;
                var total_rembourssement = 0;var total_reste = 0;
                $.each(response.data.financement, function(key,value) {
                    console.log(value);
                    total_financement += Number(value.montant);
                    total_reste += Number(value.montant) - Number(value.total_payer);
                    total_rembourssement += Number(value.total_payer);
                });
                this.totalFinancement = total_financement;
                this.totalReste = total_reste;
                this.totalRemboursement = total_rembourssement;
            });

        },
        clickLigne(liste) {
            this.financementSelection = liste;
            axios.get(this.BASE_URL + "/tresoreries/getbyactivite/" + this.financementSelection.activites_source_id).then(response => {
                this.tresoreriesdest = response.data;
                this.crudform.tresoreries_dest_id = "";
                // console.log(this.tresoreriesdest);
            });
            axios.get(this.BASE_URL + "/tresoreriesmouvement/getremboursement/" + this.financementSelection.id).then(response => {
                this.historiqueDeRemboursement = response.data;
            });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/tresoreriesmouvement/fetchdata").then(response => {
                this.listdata = response.data;
                this.clickLigne(this.financementSelection);
            });

        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.crudform.id == '' ? that.BASE_URL + "/tresoreriesmouvement/addaction" : that.BASE_URL + "/tresoreriesmouvement/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                // console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.getData();
                                that.fetchData();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                // console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/tresoreriesmouvement/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.getData();
                                that.fetchData();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },

    },
    computed: {
        
    },
    watch: {
        
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        
    },
    mounted: function() {

        this.getData();
        this.fetchData();

    }
}